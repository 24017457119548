<template>
  <div class="das_top_cont">
    <div class="history_box account_input">
      <div class="accountright_cont_box">
        <div class="flex_center_between_box">
          <h2 class="history_box-head" style="margin-top: 10px;">News for you</h2>
          <div class="sel_box computer_right_cont_box">
            <el-input class="inline-input" suffix-icon="el-icon-search" v-model="Contentval" autocomplete="off"
              placeholder="Enter Title Content" @change="seeaghInput()"></el-input>
            <!-- suffix-icon="el-icon-search" -->
          </div>
        </div>
        <div class="faq_box">
          <div class="">
            <el-collapse v-model="activeNames">
              <el-collapse-item class="item_title" v-for="(item, index) in newList" :key="index">
                <template slot="title">
                  <div class="flex_center_between_box">
                    <svg t="1723110753832" class="icon" viewBox="0 0 1024 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="5442" width="22" height="22">
                      <path
                        d="M852.432896 394.002432V150.601728c0-66.256896-54.212608-105.416704-120.467456-105.416704H120.467456C54.21056 45.185024 0 84.332544 0 150.601728v707.753984c0.198656 66.44736 54.018048 120.264704 120.467456 120.457216h780.900352c67.7376-0.026624 122.638336-54.956032 122.632192-122.701824v-462.11072h-171.567104zM312.24832 190.240768h158.63808v39.356416h-112.457728v42.108928h104.638464v39.213056h-104.638464v52.629504h116.43904v39.213056h-162.619392V190.240768zM516.096 538.624v78.08H104.448V538.624h411.648zM102.4 190.240768h44.140544l82.108416 133.974016V190.240768h43.194368v212.523008H226.42688l-80.83456-130.756608v130.756608H102.4V190.240768zM747.52 788.48H104.448v-78.082048h643.072V788.48z m-34.666496-385.716224H665.02656l-39.938048-149.901312-39.800832 149.901312h-48.830464L485.722112 190.240768h47.31904l29.964288 136.534016 36.337664-136.534016h53.215232l34.809856 138.692608 30.47424-138.692608h46.620672l-51.6096 212.523008z m235.853824 453.349376c0 26.144768-21.190656 47.335424-47.333376 47.335424-26.144768 0-47.335424-21.190656-47.335424-47.335424v-386.82624h94.666752v386.82624z"
                        p-id="5443" fill="#1296db"></path>
                    </svg>
                    <div class="message-right">
                      <div class="head-text">{{ item.title }}</div>
                      <div class="icon-time">{{ item.createTime }}</div>
                    </div>
                  </div>
                </template>

                <div class="item_cont_txt">{{ item.content }}</div>
              </el-collapse-item>

            </el-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserNews, searchUserNews } from '@/api/userApi.js';
export default {
  data() {
    return {
      activeNames: "",
      attentionDialog: false, //弹框
      Contentval: '',
      newList: []
    };
  },
  computed: {},

  created() {
    this.getUserNews()
  },
  methods: {
    getUserNews() {
      getUserNews().then(res => {
        this.newList = res.data
      })
    },
    seeaghInput() {
      searchUserNews({ key: this.Contentval }).then(res => {
        this.newList = res.data
      })
    }
  },
};
</script>
<style scoped>
.title {
  font-family: 'DMSans Bold';
  margin-top: 1.5714285714285714rem;
  margin-bottom: 1.4285714285714286rem;
}

.el-collapse {
  border: none !important;
  font-family: "Arial";
}

.item_title /deep/ .el-collapse-item__header {
  font-size: 1.1428571428571428rem;
  color: #022955;
  font-family: "Arial";
  /* margin: 0.7142857142857143rem 0; */
  background-color: #F5F5F5;
  border: none !important;
}

.item_title {
  padding: 1.4285714285714286rem;
  background-color: #F5F5F5;
  margin-bottom: 0.7142857142857143rem;
  padding-right: 0.7142857142857143rem;
}

.item_title.el-collapse-item.is-active:last-child /deep/.el-collapse-item__wrap,
.item_title:last-child /deep/ .el-collapse-item__header {
  border-width: 0;
  border: none !important;
}

.item_title /deep/ .el-collapse-item__header.is-active {
  border-bottom-color: transparent;
}

.item_cont_title {
  /* font-family: "Arial Bold"; */
  font-family: "Arial";
  /* margin-bottom: 0.7142857142857143rem; */
  color: #022955;
}

.item_cont_txt {
  font-size: 1.2857142857142858rem;
  color: #1A2332;
  padding-left: 2.357142857142857rem;
  background-color: #F5F5F5;
  padding-top: 1.1428571428571428rem;
}

.title_txt img {
  width: 1.4285714285714286rem;
  margin-right: 0.5714285714285714rem;
}

.item_title /deep/.el-collapse-item__content {
  padding-bottom: 0;
}

/* .item_title /deep/ .el-collapse-item__arrow {
    width: 1.7142857142857142rem;
    height: 1.7142857142857142rem;
    background: #ffffff;
    border-radius: 0.5714285714285714rem;
    border: 0.07142857142857142rem solid #e7eaed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 0.6428571428571429rem;
} */
/* .item_title /deep/ .el-collapse-item__arrow::before {
    content: '';
}
*/
.item_title /deep/ .el-collapse-item__arrow {
  transform: rotate(90deg);
}

.item_title /deep/ .el-collapse-item__arrow.is-active {
  transform: rotate(-90deg);
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

.link {
  color: #1290c9;
  text-decoration: underline;
}

.head-text {
  font-family: 'DMSans Bold';
  font-size: 1.4285714285714286rem !important;
  text-align: left;
  font-style: normal;
  color: #1A2332;
  line-height: 1.6428571428571428rem;
}

.icon-time {
  font-size: 1.1428571428571428rem;
  line-height: 1.6428571428571428rem;
  color: #999999;
  margin-top: 0.5714285714285714rem;
  height: 1.5714285714285714rem;
}

@media screen and (max-width: 1441px) {
  /* 时间，内容，箭头 */
  .icon-time,
  .item_cont_txt,
  .item_title /deep/ .el-collapse-item__header {
    font-size: 12px;
  }


}

.message-right {
  margin-left: 0.7142857142857143rem;
}

.sel_box /deep/ .el-input__inner {
  border-color: #ccc;
  border-radius: 0.5714285714285714rem;
  font-size: 1.1428571428571428rem;
}

/deep/.el-collapse-item__wrap {
  border: none !important
}

/* 特殊处理 */
.account_input .sel_box {
  margin-right: 0;
}

.account_input .accountright_cont_box .flex_center_between_box {
  margin-bottom: 0;
  align-items: start;
}

::v-deep .el-collapse-item__header {
  height: auto;
  line-height: 1.5;
}

.el-input .el-icon-search:before {
  width: 1.1428571428571428rem;
  height: 1.1428571428571428rem;
  background: #1A2332;
}
</style>